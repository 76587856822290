<template>
  <div class="contain-title-box" :style="{marginTop:marginTopZh,color:txtColor,marginBottom:marginBottomZh}">
    <div class="contain-title" :style="{color:colorZh,fontSize:fontSizeZh}">{{titleZh}}</div>
    <div class="contain-title-en" :style="{width:widthEn,fontSize:fontSizeEn,marginTop:marginTopEn,lineHeight:lineEn,color:colorEn}">{{titleEn}}</div>
  </div>
</template>
<script>
export default {
  props:{
    titleZh:{
      type:String,
      default:''
    },
    fontSizeZh:{
      type:String,
      default:''
    },
    marginTopZh:{
      type:String,
      default:'.8rem'
    },
    marginBottomZh:{
      type:String,
      default:'.2rem'
    },
    txtColor:{
      type:String,
      default:'#3d3d3d'
    },
    colorZh:{
      type:String,
      default:'#3d3d3d'
    },
    titleEn:{
      type:String,
      default:''
    },
    widthEn:{
      type:String,
      default:''
    },
    fontSizeEn:{
      type:String,
      default:'.2rem'
    },
    marginTopEn:{
      type:String,
      default:'.06rem'
    },
    lineEn:{
      type:String,
      default:''
    },
    colorEn:{
      type:String,
      default:'#3d3d3d'
    },
  },
//   data() {},
};
</script>
<style lang="less" scoped>
.contain-title-box {
    // height: 200px;
  text-align: center;
  color: #3d3d3d;
//   margin-top: 120px;
//   margin-bottom: 39px;
  .contain-title {
    font-size: .44rem;
  }
  .contain-title-en {
    font-size: .2rem;
    margin: auto;
  }
}
</style>
