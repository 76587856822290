<template>
  <div class="contain-title-box" :style="{marginTop:marginTopZh,color:txtColor,marginBottom:marginBottomZh}">
    <div class="contain-title" :style="{color:colorZh}">{{titleZh}}</div>
    <div class="contain-title-en" :style="{width:widthEn,fontSize:fontSizeEn,marginTop:marginTopEn,lineHeight:lineEn,color:colorEn}">{{titleEn}}</div>
  </div>
</template>
<script>
export default {
  props:{
    titleZh:{
      type:String,
      default:''
    },
    marginTopZh:{
      type:String,
      default:'120px'
    },
    marginBottomZh:{
      type:String,
      default:'39px'
    },
    txtColor:{
      type:String,
      default:'#3d3d3d'
    },
    colorZh:{
      type:String,
      default:'#3d3d3d'
    },
    titleEn:{
      type:String,
      default:''
    },
    widthEn:{
      type:String,
      default:''
    },
    fontSizeEn:{
      type:String,
      default:'20px'
    },
    marginTopEn:{
      type:String,
      default:'5px'
    },
    lineEn:{
      type:String,
      default:''
    },
    colorEn:{
      type:String,
      default:'#3d3d3d'
    },
  },
//   data() {},
};
</script>
<style lang="less" scoped>
.contain-title-box {
    // height: 200px;
  text-align: center;
  color: #3d3d3d;
  margin-top: 120px;
  margin-bottom: 39px;
  .contain-title {
    font-size: 32px;
  }
  .contain-title-en {
    font-size: 20px;
    margin: auto;
  }
}
</style>
