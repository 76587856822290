<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_banner2.png" alt="" />
      <div class="mobile-header-btn" @click="toPath('/hospital/logistics')">进一步了解</div>
    </div>
    <mobileTitle titleZh="信息化产品" marginTopZh=".46rem" marginBottomZh=".46rem"></mobileTitle>
    <mobileBox :boxList="boxList1"></mobileBox>
    <mobileTitle titleZh="智能化设备" marginTopZh=".46rem" marginBottomZh=".46rem"></mobileTitle>
    <mobileBox :boxList="boxList2"></mobileBox>
    <mobileTitle titleZh="SPD运营服务" marginTopZh=".46rem" marginBottomZh=".46rem"></mobileTitle>
    <mobileBox :boxList="boxList3"></mobileBox>
  </div>
</template>
<script>
import mobileBox from '@/components/box/mobile_box.vue'; //盒子组件
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
export default {
  components: {
    mobileTitle,
    mobileBox
  },
  data() {
    return {
      boxList1:[
        {
          src:require('../../../assets/mobile_images/mo_hispital_xxhcp1.png'),
          title:'医链云<br>SAAS医院供应链平台',
          btn:'进一步了解',
          path:'/hospital/information'
        },
        {
          src:require('../../../assets/mobile_images/mo_hispital_xxhcp2.png'),
          title:'医链云<br>区域供应链平台',
          btn:'进一步了解',
          path:'/hospital/information'
        },
        {
          src:require('../../../assets/mobile_images/mo_hispital_xxhcp3.png'),
          title:'SPD-耗材管理系统',
          btn:'进一步了解',
          path:'/hospital/information'
        },
        {
          src:require('../../../assets/mobile_images/mo_hispital_xxhcp4.png'),
          title:'SPD-药品管理系统',
          btn:'进一步了解',
          path:'/hospital/information'
        }
      ],
      boxList2:[
        {
          src:require('../../../assets/mobile_images/mo_hispital_znhsb1.png'),
          title:'智能高值柜',
          btn:'进一步了解',
          path:'/hospital/intelligence'
        },
        {
          src:require('../../../assets/mobile_images/mo_hispital_znhsb2.png'),
          title:'智能普耗柜',
          btn:'进一步了解',
          path:'/hospital/intelligence'
        },
        {
          src:require('../../../assets/mobile_images/mo_hispital_znhsb3.png'),
          title:'智能配送设备',
          btn:'进一步了解',
          path:'/hospital/intelligence'
        },
        {
          src:require('../../../assets/mobile_images/mo_hispital_znhsb4.png'),
          title:'智能输送设备',
          btn:'进一步了解',
          path:'/hospital/intelligence'
        }
      ],
      boxList3:[
        {
          src:require('../../../assets/mobile_images/mo_hispital_spdyy.png'),
          title:'SPD运营服务',
          type:1,
          btn:'进一步了解',
          path:'/hospital/operate'
        },
        
      ],
    };
  },
  methods: {
    toPath(val, index) {
      if (val == 'fbt') {
        window.open('http://csbrkjcom.huhuhu.net/index.html');
      } else {
        setTimeout(() => {
          this.$router.push(val);
        }, 500);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  .mobile-home-header {
    position: relative;
    min-height: 5rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
}
</style>
