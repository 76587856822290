<template>
  <div class="platform-box">
    <div :class="item.type?'platform-box-list platform-box-wrap':'platform-box-list'" v-for="(item, index) in boxList" :key="index" @click="toPath(item,index)">
      <img :src="item.src" alt="" />
      <div class="platform-hover-box">
        <div class="platform-hover-title" v-html="item.title"></div>
        <div class="platform-hover-txt">了解更多</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    boxList: {
      type: Array,
      default: [],
    },
  },
  methods:{
    toPath(item,index){
      if(item.path){
        this.$router.push({
          path:item.path,
          query:{
            index:index
          }
        })
      }
    }
  }
};
</script>
<style lang="less" scoped>
.platform-box {
  display: flex;
  flex-wrap: wrap;
  padding: 0 .24rem;
  box-sizing: border-box;
  .platform-box-list {
    position: relative;
    width: calc(50% - .06rem);
    cursor: pointer;
    margin-bottom: .16rem;
    img {
      width: 100%;
    }
    &:nth-child(2n + 1) {
      margin-right: .12rem;
    }
    .platform-hover-box {
      position: absolute;
      width: 100%;
      height: 1.6rem;
      left: 0;
      bottom:0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: .22rem;
      background: rgba(#000, 0.7);
      color: #fff;
      text-align: left;
      box-sizing: border-box;
      .platform-hover-title {
        font-size: .28rem;
        margin-bottom: .28rem;
        line-height: .32rem;
      }
      .platform-hover-txt {
        font-size: .2rem;
      }
    }
  }
  .platform-box-wrap {
    position: relative;
    width: 100%;
    cursor: pointer;
    margin-bottom: .16rem;
    // img {
    //   width: 100%;
    // }
    // .platform-hover-box {
    //   position: absolute;
    //   width: 100%;
    //   height: 1.6rem;
    //   left: 0;
    //   bottom: 3px;
    //   display: flex;
    //   justify-content: center;
    //   flex-direction: column;
    //   padding-left: .22rem;
    //   background: rgba(#000, 0.7);
    //   color: #fff;
    //   text-align: left;
    //   box-sizing: border-box;
    //   .platform-hover-title {
    //     font-size: .28rem;
    //     margin-bottom: .28rem;
    //     line-height: .32rem;
    //   }
    //   .platform-hover-txt {
    //     font-size: .2rem;
    //   }
    // }
  }
}
</style>
