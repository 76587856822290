<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/hospital_bg.jpg" alt="" />
        <div class="bg-text">
          <div class="bg-text-left">
            <p class="font-title">医院物流建设整体解决方案</p>
            <div class="font-title-txt mt20">
              传世科技在助力医院推动重点学科建设的同时，注重打造符合现代化医院需求的物流综合管理体系。<br />
              通过对全院后勤物资的集中管理与统一规划，借助先进的管理手段和智能化设备，形成安全、高效、<br />规范的医院物流管理体系。
            </div>
            <div class="next-btn mt30" @click="toPath('/hospital/logistics')">进一步了解</div>
          </div>
          <!-- <div class="bg-text-right">
          <p class="font-one">Technology leads transform, Data drives serviceValue</p>
          <p class="font-two">Transparent Word</p>
        </div> -->
        </div>
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <Title titleZh="信息化产品" titleEn=""></Title>
        <Box :boxList="boxList1"></Box>
        <Title titleZh="智能化设备" titleEn=""></Title>
        <Box :boxList="boxList2"></Box>
        <Title titleZh="SPD运营服务" titleEn=""></Title>
        <Box :boxList="boxList3"></Box>
        <div class="h150"></div>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
</template>
<script>
import Box from '@/components/box/box.vue'; //盒子组件
import Title from '@/components/title/title.vue'; //标题组件
import mobilePage from '@/views/hospital/mobile/index.vue'//移动端页面
export default {
  name: 'home',
  components: {
    Box,
    Title,
    mobilePage
  },
  data() {
    return {
      currentIndex: 0,
      dialogVisible: false,
      index: 0,
      boxList1: [
        {
          src: require('../../assets/images/hospital_yly.png'),
          title: '医链云-SAAS医院供应链平台',
          btn: '进一步了解',
          path: '/hospital/information',
        },
        {
          src: require('../../assets/images/hospital_ylypt.png'),
          title: '医链云-区域供应链平台',
          btn: '进一步了解',
          path: '/hospital/information',
        },
        {
          src: require('../../assets/images/hospital_ynwl.png'),
          title: 'SPD-耗材管理系统',
          btn: '进一步了解',
          path: '/hospital/information',
        },
        {
          src: require('../../assets/images/hospital_ypgl.png'),
          title: 'SPD-药品管理系统',
          btn: '进一步了解',
          path: '/hospital/information',
        },
      ],
      boxList2: [
        {
          src: require('../../assets/images/hospital_znhcg.png'),
          title: '智能高值柜',
          btn: '进一步了解',
          path: '/hospital/intelligence',
        },
        {
          src: require('../../assets/images/hospital_znphg.png'),
          title: '智能普耗柜',
          btn: '进一步了解',
          path: '/hospital/intelligence',
        },
        {
          src: require('../../assets/images/hospital_znpssb.png'),
          title: '智能配送设备',
          btn: '进一步了解',
          path: '/hospital/intelligence',
        },
        {
          src: require('../../assets/images/hospital_znsssb.png'),
          title: '智能输送设备',
          btn: '进一步了解',
          path: '/hospital/intelligence',
        },
      ],
      boxList3: [
        {
          src: require('../../assets/images/hos_block3_img1.png'),
          title: 'SPD运营服务',
          type: 1,
          btn: '进一步了解',
          path: '/hospital/operate',
        },
      ],
    };
  },
  methods: {
    getShow(index) {
      this.index = index;
    },
    onTabChange(index) {
      this.currentIndex = index;
      console.log(index);
    },
    toPath(val) {
      if (val == 'fbt') {
        window.open('http://csbrkjcom.huhuhu.net/index.html');
      } else {
        setTimeout(() => {
          this.$router.push(val);
        }, 500);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    min-height: 450px;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 45px;
          color: #3d3d3d;
        }
        .font-title-txt {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 30px;
          color: #3d3d3d;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    width: 1200px;
    .yi-box-img-txt {
      width: 49%;
      float: left;
      margin-right: 1%;
      margin-top: 1%;
      .yi-box-img-txt-bottom {
        width: 100%;
        height: 115px;
        background: #000000;
        opacity: 0.7;
        margin-top: -115px;
        .yi-left-title {
          height: 24px;
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 66px;
          color: #ffffff;
          opacity: 1;
        }
        .yi-left-btn {
          height: 16px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 66px;
          color: #ffffff;
          opacity: 1;
          margin-top: 12px;
        }
      }
    }
  }
}
</style>
