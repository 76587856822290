<template>
  <div class="platform-box">
    <div :class="item.type?'platform-box-wrap':'platform-box-list'" v-for="(item, index) in boxList" :key="index" @click="toPath(item,index)">
      <img :src="item.src" alt="" />
      <div class="platform-hover-box">
        <div class="platform-hover-title">{{ item.title }}</div>
        <div class="platform-hover-txt">进一步了解</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    boxList: {
      type: Array,
      default: [],
    },
  },
  methods:{
    toPath(item,index){
      if(item.path){
        this.$router.push({
          path:item.path,
          query:{
            index:index
          }
        })
      }
    }
  }
};
</script>
<style lang="less" scoped>
.platform-box {
  display: flex;
  flex-wrap: wrap;
  .platform-box-list {
    position: relative;
    width: calc(50% - 8px);
    cursor: pointer;
    margin-bottom: 16px;
    img {
      width: 100%;
    }
    &:nth-child(2n + 1) {
      margin-right: 16px;
    }
    .platform-hover-box {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 3px;
      opacity: 0;
      translate: 0.4s;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 0;
      padding-left: 40px;
      background: rgba(#000, 0.7);
      color: #fff;
      text-align: left;
      box-sizing: border-box;
      .platform-hover-title {
        font-size: 24px;
        margin-bottom: 12px;
      }
      .platform-hover-txt {
        font-size: 16px;
      }
    }
    &:hover .platform-hover-box {
      height: 115px;
      opacity: 1;
      transition: 0.5s;
    }
  }
  .platform-box-wrap {
    position: relative;
    width: 100%;
    cursor: pointer;
    margin-bottom: 16px;
    img {
      width: 100%;
    }
    .platform-hover-box {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 3px;
      opacity: 0;
      translate: 0.4s;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 0;
      padding-left: 40px;
      background: rgba(#000, 0.7);
      color: #fff;
      text-align: left;
      box-sizing: border-box;
      .platform-hover-title {
        font-size: 24px;
        margin-bottom: 12px;
      }
      .platform-hover-txt {
        font-size: 16px;
      }
    }
    &:hover .platform-hover-box {
      height: 115px;
      opacity: 1;
      transition: 0.5s;
    }
  }
}
</style>
