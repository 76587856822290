import { render, staticRenderFns } from "./mobile_box.vue?vue&type=template&id=587f95a9&scoped=true"
import script from "./mobile_box.vue?vue&type=script&lang=js"
export * from "./mobile_box.vue?vue&type=script&lang=js"
import style0 from "./mobile_box.vue?vue&type=style&index=0&id=587f95a9&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587f95a9",
  null
  
)

export default component.exports